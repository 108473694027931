import React from 'react';
import './short.css'

const ShortWeDo = () => {
    return (
        <div className='short_bg' id='information'>
            <div className='container short'>
                <div className='short_left'>
                    <h2 className='short_heading'>
                        Проектування
                    </h2>
                    <ul>
                        <li>Підприємств харчової промисловості</li>
                        <li>Підприємств громадського харчування</li>
                        <li>Підприємств торгово-складської мережі</li>
                        <li>Інших виробничих підприємств дотичних до харчової галузі</li>
                    </ul>
                </div>
                <div className='short_right'>
                    <h2 className='short_heading'>
                        Консалтинг
                    </h2>
                    <ul>
                        <li>Діагностичні аудити</li>
                        <li>Внутрішні аудити</li>
                        <li>Навчання персоналу</li>
                        <li>Системи менеджменту: НАССР; ISO 22000; <br/> FSSC 22000; ISO 9001; ISO 9001; GMP+; ISCC</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ShortWeDo;
