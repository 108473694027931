import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {baseUrl} from '../../Constants/api.js';
import {chatId, encodedToken} from '../../Constants/bot.js';
import "./Footer.css"
import Contact from "../../Components/Contact/Contact";
import {processFormData} from '../../Constants/const'

const Footer = ({formclass}) => {
    const {
        register, handleSubmit, formState: {errors, isValid},
    } = useForm({mode: 'onBlur'});
    const [formData] = useState({});
    const [numberQuestion, setNumberQuestion] = useState(1); // State to track question number
    const onSubmit = async (obj) => {
        const { btn, msg } = processFormData(obj);
        btn.innerText = 'Надсилання...';
        fetch(baseUrl, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({
                validator: encodedToken, chat_id: chatId, message: msg,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                btn.innerText = 'Надіслано!';
                setTimeout(function () {
                    btn.innerHTML = 'Відправити повторно';
                }, 3000);
            })
            .catch((error) => {
                btn.innerText = 'Повторіть ще раз';
                setTimeout(function () {
                    btn.innerHTML = 'Відправити ще раз';
                }, 3000);
            });
    };

    return (<div className="footer" id="contacts">
        <h1 className="footer-title">Контакти</h1>
        <div className="footer-wrapper container">
            <div className={formclass}>
                {numberQuestion === 1 && (<div className='form'>
                    <form onSubmit={handleSubmit(onSubmit)} className='footer-form'>
                        <input
                            type='text'
                            required
                            className={`name ${formData.name ? 'filled' : ''} ${errors.name ? 'error' : ''}`}
                            {...register('name', {required: true})}
                            placeholder={'Ім’я'}
                            style={{borderColor: errors.name ? 'red' : 'white'}}
                        />
                        <input
                            type='text'
                            id='phone'
                            style={{borderColor: errors.phone ? 'red' : 'white'}}
                            className={`phone`}
                            placeholder={'Номер телефону'}
                            {...register('phone', {
                                required: 'Phone number is required.', pattern: {
                                    value: /^[\]?[(]?[0-9]{3}[)]?[-\s. ]?[0-9]{3}[-\s.]?[0-9]{4,9}$/,
                                    message: 'Invalid phone number format.',
                                },
                            })}
                        />
                        <textarea
                            className={`text ${formData.text ? 'filled' : ''} ${errors.text ? 'error' : ''}`}
                            {...register('text')}
                            placeholder={'Коментар'}
                        ></textarea>
                        <div className='form-thirdBlock'>
                            <button
                                type='submit'
                                className='send-form'
                                id={'send-form'}
                                disabled={!isValid}
                            >
                                Надіслати
                            </button>
                        </div>
                    </form>
                </div>)}

                {numberQuestion === 2 && (<div className={'thankyou form-thanks'}>
                    <h3 className={'thankyou__title'}>Дякуємо вам за заявку!</h3>
                    <button
                        className={'thankyou__button'}
                        onClick={() => setNumberQuestion(1)}
                    >
                        Заповнити повторно
                    </button>
                </div>)}
            </div>
            <div className="footer-contacts">
                <div className="footer-contact">
                    <Contact name={"Гілецький Іван"} text={"Експерт з харчової безпеки"} phone={"063 612 67 67"}
                             email={"giletsky.gtc@gmail.com"} phoneLink={"tel: 063 612 67 67"}
                             emailLink={"mailto:giletsky.gtc@gmail.com"}/>
                    <Contact name={"Хоп’як Марія"} text={"Спеціаліст з харчової та кормової безпеки"}
                             phone={"063 068 48 29"} email={"khopiakmariia.gtc@gmail.com"}
                             phoneLink={"tel: 063 068 48 29"}
                             emailLink={"mailto:khopiakmariia.gtc@gmail.com"}/>
                </div>
                <div className="footer-info">
                    <h3 className="footer-info__item">Проектування харчових та виробничих підприємств;</h3>
                    <h3 className="footer-info__item">Розробка технологічних рішень виробництв;</h3>
                    <h3 className="footer-info__item">Аудити систем харчової безпеки;</h3>
                    <h3 className="footer-info__item">Розробка, впровадження, та підтримка систем харчової та кормової
                        безпеки:</h3>
                    <h3 className="footer-info__item">HACCP, ISO22000, GMP+, IOS9001, IOS14001</h3>
                </div>
            </div>
        </div>
        <div className="footer-img"></div>
    </div>);
};

export default Footer;

