import React, { useState } from 'react'
import styles from './whatWeDo.module.css'
import { whatWeDoFirst, whatWeDoSecond } from '../../Assets/Images/images'
import { motion } from 'framer-motion'

const WhatWeDo = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen1, setIsOpen1] = useState(false)

  const variants = {
    open: { opacity: 1, x: 0, display: 'block' },
    closed: { opacity: 0, x: '-100%', display: 'none' },
  }

  return (
    <div className={styles.wrap} id="whatWeDo">
      <div className="container">
        <div className={styles.wrap_firstBlock}>
          <div>
            <img
              src={whatWeDoFirst}
              alt={whatWeDoFirst}
              className={styles.wrap_firstBlock__img}
            />
            <div className={styles.rightSide}>
              <h3 className={styles.rightSide_title}>
                Проектування промислових харчових підприємств
              </h3>

              <p className={styles.rightSide_firstDescription}>
                Розробляємо Технологічні рішення для: <br />
                &nbsp;· Підприємств харчової промисловості;
                <br />
                &nbsp;· Підприємств громадського харчування;
                <br />
                &nbsp;· Підприємств торгово-складської мережі;
                <br />
                Інших виробничих підприємств дотичних до харчової галузі.
              </p>

              <p className={styles.rightSide_secondDescription}>
                Кожна з перерахованих груп підприємств має особливості в
                організації та режим роботи і вимагає спеціального підходу до
                проектування і будівництва. Однак поряд зі специфічними
                гігієнічними вимогами є також і загальні, без дотримання яких не
                може бути забезпечена висока санітарна культура роботи
                будь-якого харчового підприємства, до якої б групі воно не
                ставилося.
              </p>

              <button
                className={styles.rightSide_btn}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
              >
                Детальніше
              </button>
            </div>
          </div>

          <motion.div
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            style={{ color: 'white', zIndex: '100' }}
            transition={{ duration: 1 }}
            className={styles.infoText}
          >
            Проектування промислових харчових підприємств <br />
            <br />
            Підприємства харчової промисловості, громадського харчування,
            торгово-складської мережі, а також всі інші підприємства дотичні до
            харчової галузі мають свої особливості в організації та режим
            роботи. Саме тому вимагається спеціальний підхід до проектування та
            будівництва. Без дотримання вимог не може бути забезпечена висока
            санітарна культура роботи будь-якого вищепереліченого підприємства.
            <br />
            <br />
            Проектування промислових харчових підприємств - заводів, цехів та
            інших виробничих об'єктів - це найвищий рівень промислового
            проектування, в якому перевіряється кваліфікація
            технолога,конструкторів та архітекторів. В проекті промислового
            об'єкта необхідно врахувати всі особливості технологічного процесу,
            витримати всі міжнародні і державні вимоги харчового законодавства
            та систем харчової безпеки, вимоги та норми охорони праці й безпеки
            працівників, вимоги до рентабельності виробництва.
            <br />
            <br />
            До переліку робіт з проектування підприємств зазвичай відносять:
            <br />
            • проектування виробничого простору;
            <br />
            • проектування пов'язаних з виробничими цехами;
            <br />
            • проектування адміністративних, господарських і санітарно-побутових
            приміщень, які будуть використані персоналом промислового
            підприємства;
            <br />
            • проектування інженерних об'єктів, таких як котельні або
            трансформаторні чи дизельні підстанції.
            <br />
            <br />
            Наша компанія готова в оптимальні терміни розробити новий
            технологічний проект підприємства враховуючи великий досвід
            проектування, що дає можливість нашим технологам врахувати всі
            особливості технологічного процесу і послідовностей та вимоги систем
            харчової безпеки і державних будівельних норм.
            <br />
          </motion.div>
        </div>

        <div className={styles.wrap_firstBlock}>
          <div>
            <div className={styles.rightSide}>
              <h3 className={styles.rightSide_title}>
                Надання консалтингових послуг
              </h3>

              <p
                className={styles.rightSide_secondDescription}
                style={{ marginBottom: '20px' }}
              >
                Наші послуги:
                <br />
                <br />
                Ми пропонуємо послуги щодо розробки, впровадження та підтримки:
                <br />
                -Систем менеджменту ISO 22000, FSSC 22000, ISO 9001, ISO 14001,
                GMP+, ISCC
                <br />
                -Діагностичні аудити
                <br />
                -Внутрішні аудити
                <br />
                -Навчання персоналу
                <br />
                -ISO 22000 – Система менеджменту безпеки харчових продуктів.
                <br />
                <br />
                Переваги впровадження стандарту ISO 22000 на підприємстві:
                <br />
                - Підвищення іміджу та репутації організації;
                <br />
                - Перевага при участі в тендерних торгах;
                <br />- Розподіл відповідальності за безпеку продукції, що
                виготовляється;
                <br />
              </p>

              <button
                className={styles.rightSide_btn}
                onClick={() => setIsOpen1((isOpen1) => !isOpen1)}
              >
                Детальніше
              </button>
            </div>

            <img
              src={whatWeDoSecond}
              alt={whatWeDoSecond}
              className={styles.wrap_firstBlock__img}
            />
          </div>

          <motion.div
            animate={isOpen1 ? 'open' : 'closed'}
            variants={variants}
            style={{ color: 'white', zIndex: '100' }}
            transition={{ duration: 1 }}
            className={styles.infoText}
          >
            Надання консалтингових послуг <br />
            <br />
            Ми пропонуємо наступні послуги:
            <br />
            -НАССР – система аналізу небезпечних чинників та контролю критичних
            точок.
            <br />
            Переваги впровадження:
            <br />
            - відповідність Закону України – «Про основні принципи та вимоги до
            безпечності та якості харчових продуктів» і успішне проходження
            інспекційного нагляду;
            <br />
            - розподіл відповідальності за безпеку продукції, що виготовляється;
            <br />
            - підвищення конкурентоспроможності і престижу організації при
            участі в тендерах.
            <br />
            <br />
            -ISO 22000 – система менеджменту безпеки харчових продуктів.
            <br />
            Переваги впровадження:
            <br />
            - Перевага при участі в тендерних торгах;
            <br />
            - Розподіл відповідальності за безпеку продукції, що виготовляється;
            <br />
            - Системний підхід, тобто створення цілісного комплексу
            взаємопов’язаних процедур управління;
            <br />
            - Залучення іноземних інвесторів;
            <br />
            - Знижує рівень ризиків в сфері безпечності харчових продуктів.
            <br />
            <br />
            -FSSC 22000 - міжнародна схема сертифікації системи менеджменту
            безпечності харчових продуктів, вимоги якої базуються на
            міжнародному стандарті ISO 22000, вимогах до програм-передумов
            ISO/TS 22002-1 і FSSC 22000 Додаткові вимоги.
            <br />
            Переваги впровадження:
            <br />
            - Міжнародне визнання компаній, сертифікованих відповідно до FSSC;
            <br />
            - Підвищення довіри споживачів до продукції;
            <br />
            - Можливості виходу нові ринки, розширення вже існуючих ринків
            збуту;
            <br />
            - Залучення іноземних інвесторів;
            <br />
            - Знижує рівень ризиків в сфері безпечності харчових продуктів;
            <br />
            - Зниження кількості рекламацій за рахунок забезпечення стабільної
            якості продукції.
            <br />
            <br />
            -ISO 9001 – система менеджменту якості.
            <br />
            Переваги впровадженні:
            <br />
            - Міжнародне визнання;
            <br />
            - Задоволення потреб замовника;
            <br />
            - Інструменти для управління і контролю над процесами підприємства;
            <br />
            - Ефективний розподіл ресурсів, повноважень і відповідальності
            персоналу.
            <br />
            <br />
            -ISO 14001 – система екологічного менеджменту.
            <br />
            Переваги впровадження:
            <br />
            - Підвищення іміджу та репутації організації;
            <br />
            - Зменшення витрат;
            <br />
            - Покращення управління;
            <br />
            - Дотримання норм.
            <br />
            <br />
            -GMP+ - система менеджменту безпеки кормів.
            <br />
            Переваги впровадження:
            <br />
            - Виробництво безпечної продукції високої якості;
            <br />
            - Зниження витрат;
            <br />
            - Запобігання помилкам у виробничому процесі й усунення повторних
            ризиків;
            <br />
            - Процесний підхід;
            <br />
            - Доступ до нових міжнародних ринків.
            <br />
            <br />
            - ISCC – міжнародна сертифікація сталого розвитку та викидів
            вуглецю, є глобальним стандартом екологічно чистої та відповідально
            виробленої біомаси, біопалива та побічних продуктів.
            <br />
            Переваги впровадження:
            <br />
            - Підвищення іміджу та репутації підприємства;
            <br />
            - Допомагає виробникам брати на себе відповідальність і знижує ризик
            створення компаніями екологічно шкідливих процесів;
            <br />
            - Конкурентоспроможність, розширення бази покупців на Європейському
            та міжнародному ринку;
            <br />
            - Відповідність Європейським нормам та вимогам;
            <br />
            - Оптимізація бізнес процесів між українськими постачальниками та
            міжнародними покупцями.
            <br />
            <br />
            -Діагностичний аудит - проводиться нашими фахівцями за ініціативою
            замовника, дозволяє виявити слабкі місця в роботі системи та
            визначити шляхи їх удосконалення.
            <br />
            <br />
            -Внутрішній аудит - проводиться з метою підтвердження, що
            впроваджена система менеджменту на підприємстві функціонує
            відповідно до вимог стандарту та допомагає підтримувати функціонуючу
            систему менеджменту на належному рівні.
            <br />
            <br />
            -Навчання персоналу – організація необхідних тренінгів для Вашого
            персоналу з видачею сертифікатів, в зручному форматі (онлайн або
            офлайн).
            <br />
            Ми пропонуємо:
            <br />
            -навчання вимогам міжнародних стандартів ISO 9001, ISO 14001, ISO
            22000, GMP+, FSSC 22000;
            <br />
            -навчання внутрішніх аудиторів систем менеджменту ISO 9001, ISO
            14001, ISO 22000, GMP+, FSSC 22000.
            <br />
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo
