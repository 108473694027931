import React from 'react';
import "./Contact.css"
const Contact = ({name, text, phone, email, phoneLink,emailLink}) => {
    return (
        <div className={"contact"}>
            <h2 className="contact-name">{name}</h2>
            <p className="contact-text">{text}</p>
            <a href={phoneLink} className="contact-phone">{phone}</a>
            <a href={emailLink} className="contact-email">{email}</a>

        </div>
    );
};

export default Contact;
