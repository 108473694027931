import React from 'react';
import LidItem from "../../Components/LidItem/LidItem";
import "./Lid.css"
import {lids} from "../../Constants/textinfo.js";
const Lid = () => {
    return (
        <div className={"lid container"} id="lids">
            <LidItem info={lids[0]}/>
            <div className="vertical-line"></div>
            <LidItem info={lids[1]}/>
        </div>
    );
};

export default Lid;
