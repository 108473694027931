import './header.css'
import {useState} from "react";

function Header() {

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <div className='header'>
            <nav className="navbar navbar-expand-lg site-navbar navbar-light bg-light" id="pb-navbar">
                <div className="container">
                    <button className="navbar-toggler" onClick={handleOpen} type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample09"
                            aria-controls="navbarsExample09" aria-expanded={open} aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`collapse navbar-collapse justify-content-md-center ${open ? 'show' : ''}`}
                         id="navbarsExample09">
                        <ul className="navbar-nav">
                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#whatWeDo">Про
                                послуги</a></li>

                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#aboutUs">Про нас</a>
                            </li>
                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#clients">Клієнти</a>
                            </li>
                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#information">Інформація</a>
                            </li>
                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#lids">Керівники</a>
                            </li>
                            <li className="nav-item without-icon" onClick={handleClose}><a className="nav-link"
                                                                                           href="#contacts">Контакти</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
