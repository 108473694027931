import React from "react";
import './Clients.scss';
import {
  okko,
  fainiLody,
  evrohotel,
  lapez,
  kympel,
  shkvarka,
  bachevsky,
  celentano,
  papashion,
  mykola,
  loza,
  cheese,
  crown,
  boim,
  idlo,
} from '../../Assets/Images/images.js';

const tempData = [
  okko,
  fainiLody,
  evrohotel,
  lapez,
  kympel,
  shkvarka,
  bachevsky,
  celentano,
  papashion,
  mykola,
  loza,
  cheese,
  crown,
  boim,
  idlo,
];

export default function Clients() {
  return (
    <div className="clients container" id="clients">
      <div className="clients__title">Клієнти</div>
      <div className="clients__logos">
        {tempData.map((el, index)=>{
          return(
            <img src={el} className="clients__logo" alt="client" key={index}/>
          )
        })}
      </div>
    </div>
  );
}
