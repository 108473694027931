import {  GiletskyiIvan,
  MariaHopyak} from "../Assets/Images/images.js";


const lids = [
  {
    img: GiletskyiIvan,
    name: "Гілецький Іван",
    profession: "Керівник відділу Проектування",
    text: "Експерт з харчової безпеки, міжнародний провідний спеціаліст з розробки і впровадження систем харчової безпеки, інженер, проектувальник з досвідом роботи понад 15 років"
  },
  {
    img: MariaHopyak,
    name: "Хоп’як Марiя",
    profession: "Керівник відділу Консалтингу",
    text: "Провідний спецiалiст iз впровадження мiжнародних систем менеджменту на пiдприємствах харчової, кормової, косметологiчної та iн.галузей із досвідом роботи понад 6 років"
  }
]


export {lids}