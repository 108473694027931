import React from 'react';
import "./LidItem.css"
const LidItem = ({info}) => {
    return (
        <div className={"liditem "}>
            <img src={info.img} alt="" className="liditem-img"/>
            <h2 className="liditem-name">{info.name}</h2>
            <h3 className="liditem-profession">{info.profession}</h3>
            <p className="liditem-text">{info.text}</p>
        </div>
    );
};

export default LidItem;
