// formUtils.js
export const processFormData = (obj) => {
    let {name, phone, text} = obj;
    let btn = document.getElementById('send-form');
    let msg = `Ім'я - ${name}
Телефон - ${phone}
Коментар - ${text}
  `;

    return {btn, msg};
};
