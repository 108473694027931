import React from 'react';
import './hat.css'
import {Hat_logo} from "../../Assets/Images/images";
const Hat = () => {
    return (
        <div className='hat'>
            <div className='container  hat_content'>
                <img className='hat_logo' src={Hat_logo} alt=""/>
                <p className='hat_paragraph'>
                    Професійні та якісні послуги у сфері проектування харчових та виробничих <br/> підприємств, надання
                    консалтингових послуг за міжнародними стандартами
                </p>
            </div>
        </div>
    );
};

export default Hat;
