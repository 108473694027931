import Lid from "./Modules/Lid/Lid";
import Footer from "./Modules/Footer/Footer";
import WhatWeDo from './Modules/whatWeDo/WhatWeDo'
import Hat from "./Modules/Hat/Hat";
import ShortWeDo from "./Modules/Short_what_we_do/ShortWeDo";
import AboutUs from "./Modules/AboutUs/AboutUs";
import Clients from "./Modules/Clients/Clients";
import Header from "./Modules/Header/Header";

function App() {

    return (
        <div className="App">
            <Header/>
            <Hat/>
            <ShortWeDo/>
            <AboutUs/>
            <Clients/>
            <WhatWeDo/>
      <Lid/>
    <Footer/>
        </div>
    )
}

export default App
