import React from 'react'
import './AboutUs.scss'

import {
  worker_1,
  worker_2,
  worker_3,
  worker_4,
  worker_5,
} from '../../Assets/Images/images.js'

const tempData = [worker_1, worker_2, worker_4, worker_5, worker_3]

function AboutUs() {
  return (
    <div className="aboutUs container" id="aboutUs">
      <div className="aboutUs__title">Про нас:</div>
      <div className="aboutUs__text">
        Об'єднавши колосальний досвід у галузі сертифiкацiї, консалтингу,
        проектування харчових та виробничих підприємств, безпеки харчових
        продуктів та навчання, наша команда Giletsky TechnoConsult стала
        провiдними спецiалiстами послуг у галузi пiдтвердження вiдповiдностi
        систем менеджменту та проектування харчових та виробничих підприємств,
        згідно всіх міжнародних вимог.
        <br /> <br />
        Команда спецiалiстiв Giletsky TechnoConsult спецiалiзуємося не тiльки на
        розумiннi потенцiалу передових iдей, але й реалiзуємо цей потенцiал на
        практицi, щоб отримати результат як у короткостроковiй, так i
        довгостроковiй перспективi
      </div>

      {tempData.map((el, index) => {
        return (
          <div
            className={'bubble bubble__' + (index + 1) + ' bounce'}
            key={index}
          >
            <img src={el} alt="person" />
          </div>
        )
      })}
    </div>
  )
}

export default AboutUs
